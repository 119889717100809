<template>
  <menu-layout :value="0">
    <gaudy-title label="专业科目"></gaudy-title>
    <div class="specialized-top">
      <div class="top-one">
        <div class="top-one-top">
          <div class="top-one-top-left">学习进度</div>
          <div class="top-one-top-right">
            {{ Statistics && Statistics.xueXiJinDu }}%
          </div>
        </div>
        <div>
          <el-progress
            :text-inside="true"
            define-back-color="rgba(186, 41, 38, 0.1)"
            color="#B50413"
            :show-text="false"
            :stroke-width="44"
            :percentage="Statistics && Statistics.xueXiJinDu"
          ></el-progress>
        </div>
      </div>

      <div class="top-tow">
        <div class="top-tow-top">学习时间</div>
        <div class="top-tow-center">
          {{ Statistics && Statistics.startTime }} ~
          {{ Statistics && Statistics.endTime }}
        </div>
        <div class="top-tow-bottom">进行中</div>
      </div>

      <div class="top-three">
        <div class="top-three-top">课程统计</div>
        <div class="top-three-bottom">
          <div class="top-three-bottom-card">
            <div class="value">{{ Statistics && Statistics.keChengShu }}</div>
            <div class="text">
              {{ Statistics && Statistics.isClass ? "学时数" : "课程数" }}
            </div>
          </div>
          <div class="top-three-bottom-card">
            <div class="value">{{ Statistics && Statistics.weiWanCheng }}</div>
            <div class="text">未完成</div>
          </div>
          <div class="top-three-bottom-card">
            <div class="value">{{ Statistics && Statistics.daiKaoShi }}</div>
            <div class="text">待考试</div>
          </div>
          <div class="top-three-bottom-card">
            <div class="value">
              {{ Statistics && Statistics.kaoShiTongGuo }}
            </div>
            <div class="text">考试通过</div>
          </div>
        </div>
      </div>
    </div>
    <div class="body_contents">
      <div>
        <radio-tab :list="selectList" @click="filterTypeSelect" />
        <div class="playerList">
          <div v-if="playerList.length > 0">
            <div
              class="play-card"
              v-for="(item, index) in playerList"
              :key="index"
              @click="
                keepAliveNavigation(
                  '/majorPlayerPage',
                  {
                    courseId: item.courseId,
                    studyCourseId: item.studyCourseId,
                  },
                  true,
                  false
                )
              "
            >
              <div
                class="cover"
                :style="{ backgroundImage: `url(${item.videoImg})` }"
              >
                <div
                  v-if="item.ifQualified === 1"
                  class="status-tags"
                  :style="{ background: '#549A54' }"
                >
                  合格
                </div>
                <div
                  v-else
                  class="status-tags"
                  :style="{
                    background: item.status === 1 ? '#549A54' : '#E6A23C',
                  }"
                >
                  {{ item.status === 1 ? "待考试" : "未学习" }}
                </div>
              </div>
              <div class="play-card-box">
                <div class="play-card-title" :title="item.courseName">
                  {{ item.courseName }}
                </div>
                <div class="play-card-psn">
                  <svg-icon
                    icon="icon-keshi"
                    style="margin-right: 5px; font-size: 16px"
                  />
                  <div style="min-width: 44px" :title="`${item.credits}学时`">
                    {{ item.credits }}学时
                  </div>
                </div>
                <div class="play-card-psn">
                  <svg-icon
                    icon="icon-jiangshi"
                    style="margin-right: 5px; font-size: 16px"
                  />
                  <div style="min-width: 44px" :title="item.lecturer">
                    {{ item.unitSpeaker }}
                  </div>
                </div>
                <div
                  class="progress"
                  v-if="item.PDFurl && JSON.parse(item.PDFurl) && JSON.parse(item.PDFurl).length > 0"
                  @click.stop="coursewareDownload(item)"
                >
                  <svg-icon icon="icon-fujian" />
                  文字版课件下载
                </div>
              </div>
              <div class="play-card-box-right">
                <div class="play-card-box-right-progress">
                  <el-progress
                    type="circle"
                    :width="80"
                    define-back-color="rgba(216, 216, 216, 0.4)"
                    color="#BA2926"
                    :stroke-width="8"
                    :percentage="item.percent"
                    :show-text="false"
                  ></el-progress>
                  <div class="circleCenter">
                    <div>{{ item.percent }}%</div>
                    <span>观看进度</span>
                  </div>
                </div>
                <div class="play-card-box-right-text">
                  <svg-icon icon="icon-qianwang" class="icon" />
                  <div class="text">进入学习</div>
                </div>
              </div>
            </div>
          </div>
          <el-empty
            v-if="playerList.length <= 0"
            description="暂无课程"
          ></el-empty>
        </div>
      </div>
    </div>
  </menu-layout>
</template>
<script>
import GaudyTitle from "@/components/gaudyTitle.vue";
import RadioTab from "@/components/radioTab.vue";
import SvgIcon from "@/components/svgIcon.vue";
import MenuLayout from "@/components/MenuLayout.vue";
import * as storage from "@/utils/storage";
import {
  getCoursepPublicDemandAccount,
  getCoursepUserCourseList,
} from "@/api/specializedController";

export default {
  name: "home",
  components: {
    MenuLayout,
    SvgIcon,
    RadioTab,
    GaudyTitle,
  },
  data() {
    return {
      Statistics: {},
      cursor: 0,
      selectList: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "未完成",
          value: 1,
        },
        {
          label: "待考试",
          value: 2,
        },
        {
          label: "考试通过",
          value: 3,
        },
      ],
      playerList: [],
      filterType: 0,
    };
  },
  computed: {
    yearId() {
      return storage.getStore("yearId");
    },
  },
  activated() {
    this.handleRefresh();
    // this.inspectRefresh();
  },
  methods: {
    // 课件下载
    coursewareDownload(item) {
      if (item.PDFurl) {
        const PDFUrl = JSON.parse(item.PDFurl)[0];
        const a = document.createElement("a");
        a.href = PDFUrl.url;
        a.download = PDFUrl.name;
        a.click();
      }
    },
    // 获取筛选项
    filterTypeSelect(data) {
      this.filterType = data.value;
      this.getCourseList();
    },
    // 获取统计
    getStatistics() {
      var that = this;
      getCoursepPublicDemandAccount({ trainingPlanId: this.yearId }).then(
        (res) => {
          that.Statistics = res.data;
        }
      );
    },
    // 获取列表
    getCourseList() {
      var that = this;
      that.playerList = [];
      getCoursepUserCourseList({
        trainingPlanId: this.yearId,
        filterType: this.filterType,
        type: 0,
        ifExam: 0,
      }).then((res) => {
        that.playerList = res.data.map((item) => {
          if (item.videoUrl) {
            const url = JSON.parse(item.videoUrl)[0].url || "";
            item.videoImg = url.replace(".mp4", ".jpg").replace(".MP4", ".jpg");
          } else {
            item.videoImg = "";
          }
          return item;
        });
      });
    },

    handleRefresh() {
      this.getStatistics();
      this.getCourseList();
    },
  },
};
</script>
<style scoped lang="scss">
.pagex {
  margin-bottom: 25px;
}

.body_contents {
  width: 100%;
  background: #ffffff;
  padding: 16px 24px;
  box-sizing: border-box;
  border: 1px solid #eceef1;
}

.specialized-top {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 2fr;
  box-sizing: border-box;
  margin: 15px auto;

  .top-one {
    width: 100%;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    /deep/ .el-progress-bar__outer {
      border-radius: 16px !important;
    }
    /deep/ .el-progress-bar__inner {
      border-radius: 16px !important;
    }

    .top-one-top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 13px;

      .top-one-top-left {
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
      }

      .top-one-top-right {
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0px;

        font-feature-settings: "kern" on;
        color: #ba2926;
      }
    }
  }

  .top-tow {
    width: 100%;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;

    .top-tow-top {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
    }

    .top-tow-center {
      font-family: Source Han Sans;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;
      margin: 8px auto;
      white-space: nowrap;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      /* 亮色模式/字体色/正文 */
      color: #505363;
    }

    .top-tow-bottom {
      font-family: Source Han Sans;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      width: max-content;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #b50413;
      box-sizing: border-box;
    }
  }

  .top-three {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    padding: 10px;

    .top-three-top {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0em;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
    }

    .top-three-bottom {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-top: 10px;
      .top-three-bottom-card {
        padding: 8px 16px;
        flex-grow: 1;
        align-self: stretch;
        background: #b50413;
        text-align: center;
        border-radius: 4px;
        .value {
          font-family: HarmonyOS Sans SC;
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;

          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #ffffff;
        }
        .text {
          font-family: Source Han Sans;
          font-size: 12px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0em;

          font-variation-settings: "opsz" auto;
          font-feature-settings: "kern" on;
          color: #ffffff;
        }
      }
    }
  }
}

.checkMore {
  cursor: pointer;
  width: 80px;
}

.playerList {
  width: 100%;
  box-sizing: border-box;

  .play-card {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    padding: 12px;
    margin-bottom: 16px;

    .cover {
      position: relative;
      width: 210px;
      height: 120px;
      min-width: 210px;
      max-width: 210px;
      background-repeat: no-repeat;
      background-size: cover;

      .status-tags {
        position: absolute;
        font-size: 12px;
        color: #fff;
        padding: 2px 8px;
        top: 2px;
        right: 2px;
        border-radius: 4px;
      }
    }

    .play-card-box {
      padding: 0 12px 0 12px;
      max-width: 518px;
      min-width: 518px;
      .play-card-title {
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        min-height: 40px;
      }

      .play-card-psn {
        width: 100%;
        color: #818496;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        margin: 8px 0;

        .mout {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }

      .play-card-study {
        font-size: 14px;
        color: #71b158;
        margin: 8px 0;
      }

      .progress {
        display: flex;
        width: -moz-max-content;
        width: max-content;
        background: rgba(186, 41, 38, 0.1);
        padding: 4px 8px;
        box-sizing: border-box;
        border: 1px solid #ba2926;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: max-content;
        background: rgba(186, 41, 38, 0.1);
        padding: 4px 8px;
        box-sizing: border-box;
        border: 1px solid #ba2926;
        font-family: Source Han Sans;
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        letter-spacing: 0em;
        border-radius: 4px;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #ba2926;
      }

      .progress-plan {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 20px;
        background: #60b364;
      }
    }
    .play-card-box-right {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: stretch;
      box-sizing: border-box;
      .play-card-box-right-progress {
        position: relative;
        text-align: center;
        background: rgba(186, 41, 38, 0.06);
        padding: 12px 16px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        .circleCenter {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          div {
            font-family: Source Han Sans;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0px;

            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #ba2926;
          }

          span {
            font-family: Source Han Sans;
            font-size: 10px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 0px;

            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            /* 亮色模式/字体色/正文 */
            color: #505363;
          }
        }
      }
      .play-card-box-right-text {
        background: #ba2926;
        padding: 12px 16px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        .text {
          white-space: nowrap;
          font-family: Source Han Sans;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0px;

          font-variation-settings: "opsz" auto;
          color: #ffffff;
        }
        .icon {
          color: #ffffff;
        }
      }
    }
  }

  .play-card:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}

.information {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .information-card {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;

    .information-card-title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: flex-start;

      .title {
        color: #21252e;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .status {
        font-size: 12px;
        width: max-content;
        white-space: nowrap;
        padding: 2px 5px;
        border-radius: 4px;
        color: #fff;
      }
    }

    .information-card-abstract {
      font-size: 12px;
      padding: 10px;
      background: #eff3f2;
      border-radius: 4px;
      margin: 8px 0px;

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .information-card-bottom {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;

      .information-card-bottom-time {
        font-size: 12px;
        color: #818496;
      }

      .wordsNum-box {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        color: #818496;

        .wordsNum {
          color: #1677fe;
          margin-right: 5px;
        }
      }
    }
  }

  .information-card:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}
</style>
